.ubo {
    &.insurance-modal {
        font-size: 14px;
        ul {
            list-style: none;
            padding-left: 30px;
            li {
                position: relative;
                padding: 4px 0;
                &:before {
                    content: "";
                    position: absolute;
                    top: 8px;
                    width: 13px;
                    height: 13px;
                    left: -24px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='12' viewBox='0 0 13 12' fill='none' %3E%3Cpath d='M0.951866 5.85163L0 6.91057L5.72342 12L13 0.770325L11.7995 0L5.42991 9.84553L0.951866 5.85163Z' fill='%23F8B912' /%3E%3C/svg%3E");
                }
            }
        }
    }
}

.ksc-alert-error-icon {
    @extend .fa-white-times-circle;
    @extend .icon-lg;
}

.ksc-alert-warning-icon {
    @extend .fa-white-exclamation-triangle;
    @extend .icon-lg;
}

.ksc-alert-info-icon {
    @extend .fa-white-info-circle;
    @extend .icon-lg;
}

.ksc-alert-success-icon {
    @extend .fa-white-check-circle;
    @extend .icon-lg;
}

.ksc-alert-close-icon {
    @extend .fa-darkgrey-times;
    @extend .icon-sm;
}

.ksc-alert {
    .alert-content p {
        margin: 0;
    }
}

.ksc-alert-sticky {
    background: #ffffff;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    transition: top .5s;

    &.header-offset {
        @media (max-width: $screen-sm-max) {
            transition: top .5s;
            top: 98px;
        }
    }

    &.product-offset {
        @media (min-width: $screen-md-min) {
            transition: top .5s;
            top: 50px;
        }
    }

    .ksc-alert {
        margin: 0 auto 5px;
        width: 100%;

        @media (min-width: $screen-sm-min) {
            width: 570px;
        }

        @media (max-width: $screen-sm-max) and (min-width: $screen-sm-min) {
            width: 100%;
            max-width: 770px;
        }

        @media (min-width: $screen-md-min) {
            width: 962px;
        }

        @media (min-width: $screen-lg-min) { 
            width: 1170px;
        }
    }
}
%link-default{
	color: $link-color;
    font-weight: normal;

    &:active, &:hover:active {
        color: $link-active-color;
    }

    &:hover, &:focus {
        color: $link-hover-color;
    }

    &:hover {
        text-decoration: underline;
    }
}

%link-collection{
    color: $link-collection-color;

    &:active, &:hover:active {
        color: $link-active-color;
    }

    &:hover, &:focus {
        color: $link-hover-color;
    }

    &:hover {
        text-decoration: underline;
    }
}
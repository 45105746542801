.icon-xl {
    font-size: 64px;
}

.icon-lg {
    font-size: 32px;
}

.icon-md {
    font-size: 24px;
}

.icon-sm {
    font-size: 16px;
}

.icon-xs {
    font-size: 8px;
}

.icon-20 {
    font-size: 20px;
}

.icon-32 {
    font-size: 32px;
}

.icon-29 {
    font-size: 29px;
}

.icon-50 {
    font-size: 50px;
}
/*#region Variables */
$environment-info-font-size: 11px;
/*#endregion */

.dump {
    font-family: monospace;
    word-wrap: break-word;
    color: black;
}

.dump em {
    font-style: normal;
    font-weight: bold;
}

.environment-info {
    color: #eee;
    background: #333;
    font-size: $environment-info-font-size;
    padding: 0 0 0 10px;
    word-wrap: break-word;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &[data-env=LOCALHOST] {
        background: #a00;
    }

    &[data-env=DEV] {
        background: #cc7905;
    }

    &[data-env=TEST] {
        background: #080;
    }

    em {
        font-weight: bold;
        font-style: normal;
        color: white;
    }

    a {
        color: white;
        text-decoration: underline;
    }

    button.btn-default {
        color: #ccc;
        background: #333;
        border: 2px solid black;
        border-top-color: #666;
        border-left-color: #666;
        border-right-color: #000;
        border-bottom-color: #000;

        &:active {
            color: #ccc;
            background: #333;
            border: 2px solid black;
            border-top-color: #000;
            border-left-color: #000;
            border-right-color: #666;
            border-bottom-color: #666;
        }
    }

    @media (max-width: 991px) {
        display: none;
    }
}
@import "_variables";
@import "_placeholders";

.btn-large {
    @extend %btn-default;
    min-width: $button-large-min-width;
    text-align: center;
    padding-top: $button-large-top-bottom-padding;
    padding-bottom: $button-large-top-bottom-padding;
    &.btn-icon{
        .icon{
            top: 13px;
        }
    }
}

.btn-medium {
    @extend %btn-default;

    &.added-to-cart, &.added-to-cart:active, &.added-to-cart:hover, &.added-to-cart:focus,&.added-to-cart:focus:active {
        .checkmark {
            width: 20px;
            height: 20px;
            margin-top: $button-add-to-cart-checkmark-margin-md;
            stroke-width: 4;
        }
        .text {
            margin-left: 25px;
        }
    }

}

.btn-small {
    @extend %btn-default;
    padding: $button-small-top-bottom-padding $button-small-left-right-padding;


    &.added-to-cart, &.added-to-cart:active, &.added-to-cart:hover, &.added-to-cart:focus,&.added-to-cart:focus:active {
        .checkmark {
            width: 15px;
            height: 15px;
            stroke-width: 4;
            margin-top: $button-add-to-cart-checkmark-margin-sm;
        }
        .text {
            margin-left: 20px;
        }
    }

    &.btn-icon{
        padding-left: 35px;
        &.secondary{
            padding-left: 30px;
        }
        &:before {
            width: 30px;
        }
        .icon {
            top: 5px;
            left: 5px;
        }
    }
}

.dropdown-btn {
    padding: $dropdown-btn-padding;
    position: relative;

    select {
        -webkit-appearance: none;
        -moz-appearance: none;

        width: 100%;
        text-transform: lowercase;
        @extend .btn-medium;
        @extend .tertiary-white;

        option {
            background-color: white;
        }
    }

    select::-ms-expand {
        display: none;
    }

    .icon {
        position: absolute;
        pointer-events: none;
        top: $dropdown-btn-padding;
        right: $dropdown-icon-right-indent;
        transform: translateY(50%);
    }
}

%button-default {
    border-radius: $button-border-radius;
    border: 0;
    border-bottom: $button-border-bottom;
    border-bottom-width: $button-border-bottom-width;
    cursor: pointer;
    white-space: nowrap;
    text-transform: $button-text-transformation;
    font-size: $button-font-size;
    color: $button-color;
    background: $button-background;
    display: inline-block;
    padding: $button-top-bottom-padding $button-left-right-padding;
    line-height: normal;
    user-select: none;
    position: relative;
    vertical-align: bottom;
    overflow: visible;

    &:hover,
    &:focus {
        color: $button-color;
        background: $button-background-hover;
        text-decoration: $button-hover-text-decoration;
    }

    &:active {
        color: $button-color;
        background: $button-background-active;
        text-decoration: $button-hover-text-decoration;
        border-bottom-width: $button-border-bottom-width-active;
        margin-top: $button-border-bottom-width -
            $button-border-bottom-width-active;
    }
}

%button-primary {
    color: $button-primary-color;
    background: $button-primary-background;
    border-bottom-color: $button-primary-border-bottom-color;

    &:hover,
    &:focus {
        background: $button-primary-background-hover;
    }

    &:active {
        background: $button-primary-background-active;
    }
}

%button-tertiary-grey {
    background: $button-tertiary-grey-background-color;

    &:hover,
    &:focus {
        background: $button-tertiary-grey-background-hover;
    }

    &:active {
        background: $button-tertiary-grey-background-active;
    }
}

%button-icon {
    padding-left: 50px;

    &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 40px;
        top: 0;
        left: 0;
        background: $button-secondary-background;
        border-radius: $button-border-radius 0 0 $button-border-radius;
        box-shadow: $button-icon-shadow;
    }

    &:hover {
        &:before {
            background-color: $button-secondary-background-hover;
        }
    }

    &:active {
        &:before {
            background-color: $button-secondary-background-active;
            box-shadow: $button-icon-shadow-active;
        }
    }

    .icon {
        position: absolute;
        color: $button-secondary-color;
        top: 9px;
        left: 10px;
        text-align: center;
    }
}

.border-primary-color {
    color: $button-primary-color;
    background: $button-primary-background;

    &:before {
        border-bottom: 2.75em solid $button-primary-background;
    }
}

#block-content {
    .button-primary {
        @extend %button-primary;
        font-weight: normal;

        &:hover,
        &:active,
        &:focus {
            color: $button-primary-color;
        }
    }

    .button-secondary {
        @extend %button-tertiary-grey;
        font-weight: normal;
    }

    .button-small {
        @extend .btn-small;
    }

    .button-buy-big {
        @extend .btn-large;
        font-size: $button-font-size;
        font-weight: normal;
        width: auto;
        height: auto;
    }

    .configurator .button-buy-big {
        min-width: 85px;
        display: block;
    }

    .button-preorder {
        @extend .secondary, .pre-order-button;
        color: $button-secondary-color;
        font-weight: normal;

        &:hover,
        &:active,
        &:focus {
            color: $button-secondary-color;
        }
    }

    .shopping-cart,
    .shoppingCart {
        .button {
            border-radius: $button-border-radius;
            border: 0;
            border-bottom: $button-border-bottom;
            border-bottom-width: $button-border-bottom-width;
            cursor: pointer;
            white-space: nowrap;
            text-transform: $button-text-transformation;
            font-size: $button-font-size;
            color: $button-color;
            background: $button-background;
            display: inline-block;
            padding: $button-top-bottom-padding $button-left-right-padding;
            line-height: normal;
            user-select: none;
            position: relative;
            vertical-align: bottom;
            overflow: visible;

            &.button-primary {
                @extend %button-primary;
                font-weight: normal;
            }

            &.button-secondary {
                @extend %button-tertiary-grey;
                font-weight: normal;
            }

            &.button-small {
                padding: $button-small-top-bottom-padding
                    $button-small-left-right-padding;
            }
        }
    }

    #checkout {
        .button,
        .claim-code-button {
            @extend %button-default;

            &.button-large {
                padding: $button-large-top-bottom-padding;
            }
        }

        .button-primary {
            @extend %button-primary;
            font-weight: normal;

            &:hover,
            &:focus {
                color: $button-primary-color;
            }
        }

        .button-secondary {
            font-weight: normal;
        }

        #addNewAddress {
            vertical-align: middle;
        }

        #ChooseCollectionPoint {
            padding: $button-large-top-bottom-padding;
        }

        .deliverymethod-box {
            .button-medium {
                @extend %button-default;

                .button-secondary {
                    @extend %button-tertiary-grey;
                    font-weight: normal;
                }
            }

            #ChooseCollectionPoint {
                &:active {
                    margin-top: 11px;
                }
            }

            #btnPostCodeSearch {
                padding: $button-small-top-bottom-padding + 1
                    $button-small-left-right-padding;

                &:active {
                    margin-top: 5px;
                }
            }

            #closeSearch {
                border-radius: $button-border-radius;
                border: 0;
                border-bottom: $button-border-bottom;
                border-bottom-width: $button-border-bottom-width;
                background: $button-tertiary-grey-background-color;

                &:hover,
                &:focus {
                    background: $button-tertiary-grey-background-hover;
                }

                &:active {
                    background: $button-tertiary-grey-background-active;
                    margin-top: 1px;
                }
            }
        }

        #ShowNewAddressWrapper {
            padding-top: 10px;

            .button {
                margin-top: 0;

                &:active {
                    margin-top: 1px;
                }
            }
        }

        .checkout-applygiftcards {
            .fancy-input {
                margin-bottom: 10px;
            }

            .claim-code-button {
                margin-top: 0;

                &:active {
                    margin-top: 1px;
                }
            }
        }
    }

    #account,
    #account-home {
        .button-secondary {
            @extend %button-default;
            @extend %button-tertiary-grey;
            vertical-align: middle;
            font-weight: normal;
        }
    }

    .config-boxes .config {
        .button-buy-big {
            width: auto;
            min-width: 85px;

            &:active {
                margin-top: 13px;
            }
        }

        .cto {
            li.button.button-large.button-secondary {
                @extend %button-default;
                @extend %button-tertiary-grey;
                @extend .btn-large;
                box-sizing: border-box;
                display: inline-block;
                height: auto;
                padding: 0;
                width: auto;
                min-width: 85px;
                max-width: 148px;

                &:active {
                    margin-top: 7px !important;
                }

                a {
                    padding: $button-large-top-bottom-padding
                        $button-left-right-padding;
                    font-weight: normal;
                    display: block;
                    max-width: 100%;
                    word-wrap: normal;
                    white-space: normal;
                    color: $button-color;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: $button-color;
                    }
                }
            }
        }
    }

    #product-info .primary-container {
        .product-order-info .button:active {
            margin-top: 11px;
        }

        .button {
            @extend %button-default;
            font-size: $button-font-size-larger;
        }

        .button-preorder {
            @extend .button-preorder;
        }

        .button-primary {
            @extend %button-primary;

            &.button-download {
                @extend %button-icon;

                i.icon {
                    @extend .fa-white-download;
                    @extend .icon-20;
                    background-color: transparent;

                    .download {
                        display: none;
                    }
                }
            }
        }

        .button-secondary {
            &.button-download {
                @extend %button-tertiary-grey;
                @extend %button-icon;
                color: $button-color;
                border-bottom-color: $button-tertiary-grey-border-bottom-color;

                i.icon {
                    @extend .fa-white-cogs;
                    @extend .icon-20;
                    background-color: transparent;

                    .icon-configure {
                        display: none;
                    }
                }
            }
        }
    }

    .products.list .product-box .buy-button-wrapper {
        .button-buy-small {
            &.button-preorder {
                font-size: $button-font-size;
                padding: $button-small-top-bottom-padding
                    $button-small-left-right-padding;
            }
        }
    }

    .products.list .product-box .buy-button-wrapper.product-download,
    .rr {
        .button-buy-small {
            &.button.button-primary.button-small.gradient.button-download {
                @extend %button-icon;
                padding-left: 35px;
                height: auto;

                &:before {
                    width: 30px;
                }

                .icon {
                    @extend .fa-white-download;
                    @extend .icon-20;
                    top: 5px;
                    left: 5px;
                    background-color: transparent;

                    .download {
                        display: none;
                    }
                }
            }
        }

        .button-preorder {
            @extend .secondary, .pre-order-button;

            &:hover,
            &:active,
            &:focus {
                color: $button-secondary-color;
            }
        }
    }

    .rr {
        &.horizontal-bar .items-container .items .item-container .rr-buy-area {
            height: 43px;
        }

        .button-buy-small.button.button-small.gradient {
            margin-top: 0;
            top: auto;

            &:active {
                margin-top: 1px;
            }

            &.button-preorder {
                color: $button-secondary-color;
                font-size: $button-font-size;
                padding: $button-small-top-bottom-padding
                    $button-small-left-right-padding;
            }

            &.button-download {
                left: 0;
                font-size: 10px;
                line-height: 14px;
            }
        }
    }
}

#smartassist-container {
    .smrt42-advisor
        .smrt42-results
        .smrt42-products
        .smrt42-product
        .smrt42-item-container-2
        .smrt42-product-details
        .smrt42-link-button {
        @extend %button-default;
        @extend %button-primary;
        font-weight: normal;
        position: absolute;
        right: 25px;
        bottom: 25px;
    }
}

@keyframes jump {
 0%{
	transform: translateY(0);
 }
 20%{
	transform: translateY(0);
 }
 40%{
	transform: translateY(-15px);
 }
 50%{
	transform: translateY(0);
 }
 60%{
	transform: translateY(-5px);
 }
 80%{
	transform: translateY(0);
 }
 100%{
	transform: translateY(0);
 }
}

.jumpAnimation{
    animation: jump 1s ease 0s infinite normal;
}
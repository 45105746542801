$border-color: #f1f1f1 !default;
$small-gutter: $grid-space/2;

.uppercase {
    text-transform: uppercase;
}

.fullwidth {
    width: 100%;
}

.nowrap {
    white-space: nowrap;
}

.no-gutter {
    margin-left: 0;
    margin-right: 0;

    [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
    }

    &[class*="col-"] {
        padding-left: 0;
        padding-right: 0;
    }
}

.no-gutter-right {
    margin-right: 0;

    [class*="col-"] {
        padding-right: 0;
    }

    &[class*="col-"] {
        padding-right: 0;
    }
}

.no-gutter-left {
    margin-left: 0;

    [class*="col-"] {
        padding-left: 0;
    }

    &[class*="col-"] {
        padding-left: 0;
    }
}

.no-gutter-xs {
    @media (max-width: $screen-xs-max) {
        margin-left: 0;
        margin-right: 0;

        [class*="col-"] {
            padding-left: 0;
            padding-right: 0;
        }

        &[class*="col-"] {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.small-gutter {
    margin-left: -$small-gutter;
    margin-right: -$small-gutter;

    [class*="col-"] {
        padding-left: $small-gutter;
        padding-right: $small-gutter;
    }

    &[class*="col-"] {
        padding-left: $small-gutter;
        padding-right: $small-gutter;
    }
}

.top-border {
    border-top: 1px solid $border-color;
}

.left-border {
    border-left: 1px solid $border-color;
}

.right-border {
    border-right: 1px solid $border-color;
}

.bottom-border {
    border-bottom: 1px solid $border-color;
}

.absolute-center {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.element-centered-vertically {
    top: 50%;
    transform: translate(0, -50%);
}

.touch-friendly-link {
    display: inline-block;
    line-height: 40px;
    min-width: 40px;
}

.btn.text-left {
    text-align: left;
}

.flexbox-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;

    &.box {
        flex-direction: row;
    }

    &.list {
        flex-direction: column;
    }
}
//Accessibilty WCAG 2.0

.hidden-visible-SR {
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    color: transparent;
    background: transparent;
    border: 0;
}

.no-margin {
    margin: 0;
}

.overflow-hidden {
    overflow: hidden;
}
//Adds line break from sm and larger
@media (min-width: $screen-sm-min) {
    .line-break-sm {
        display: block;
    }
}

@media (min-width: $screen-md-min) {
    .pull-right-md {
        float: right;
    }
}

.visibility-hidden {
    visibility: hidden !important;
}

// Delete this when fadeVisible and fadeVisibility bindsings are gone
.fadeShow {
    transition: opacity 400ms;
    opacity: 1;
}

.fadeHide {
    transition: opacity 400ms;
    opacity: 0;
}

.bindingFadeIn {
    animation: fadeIn ease 400ms;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
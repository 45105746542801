/*#region Variabless */
$alert-error-background-color: #ff0000 !default;
$alert-warning-background-color: #ffdf00 !default;
$alert-info-background-color: #009ACD !default;
$alert-success-background-color: #00c200 !default;
$alert-min-heigh: 40px !default;
$alert-border-radius: 3px !default;
/*#endregion */

.ksc-alert {
  padding: 0;
  padding-bottom: 0;
  margin: 5px 0 25px 0;
  min-height: $alert-min-heigh;
  border-radius: $alert-border-radius;

  .alert-header {
    display: block;
    background: none;
    margin: 0;
    padding: 0;
    text-align: center;
    display: table-cell;
    vertical-align: middle;

    .icon-wrapper {
      min-width: 50px;
    }
  }

  .alert-content {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 40px 10px 10px;
    position: relative;
    display: table-cell;
    width: 100%;
    height: $alert-min-heigh;
    vertical-align: middle;

    .icon {
      position: absolute;
    }

    .close-button {
      cursor: pointer;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      position: absolute;
      display: inline-block;
      text-align: center;

      .ksc-alert-close-icon {
        position: absolute;
        top: 12px;
        right: 10px;
      }
    }
  }

  &.ksc-alert-error {
    border: 1px solid $alert-error-background-color;

    .alert-header {
      background: $alert-error-background-color;
    }
    .alert-content {
      background: #ffdedd;
    }
  }

  &.ksc-alert-warning {
    border: 1px solid $alert-warning-background-color;

    .alert-header {
      background: $alert-warning-background-color;
    }

    .alert-content {
      background: #fffbde;
    }
  }

  &.ksc-alert-info {
    border: 1px solid $alert-info-background-color;

    .alert-header {
      background: $alert-info-background-color;
    }
    .alert-content {
      background: #e8f4fb;
    }
  }

  &.ksc-alert-success {
    border: 1px solid $alert-success-background-color;

    .alert-header {
      background: $alert-success-background-color;
    }

    .alert-content {
      background: #dff7df;
    }
  }
}

.ksc-alert-sticky {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 3px;
  z-index: 1000;

  .ksc-alert {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 25px;
  } 
}
$overlay-color: #000 !default;

@mixin overlay-template {
    background: $overlay-color;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1099;
    opacity: 0.3;

    &.fixed {
        position: fixed;
    }

    &.absolute {
        position: absolute;
    }
}


.overlay {
     @include overlay-template;
}

@media (max-width:$screen-sm-max) {
    .overlay-sm {
         @include overlay-template;
    }

}

@media (max-width:$screen-xs-max) {
    .overlay-xs {
         @include overlay-template;
    }
}
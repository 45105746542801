$sticky-box-width: 150px;
$sticky-box-margin: 10px;

.sticky-box-wrapper {
    display: none;

    @media (min-width: 1540px) {
        display: block;
        position: fixed;
        left: 50%;
        margin-left: -($screen-lg-container / 2) - $sticky-box-width - $sticky-box-margin;
        z-index: 1;
    }

    @media(min-width: $screen-xl-min) {
        margin-left: -($screen-xl-container / 2) - $sticky-box-width - $sticky-box-margin;
    }
    
    @media(min-width: $screen-xxl-min) {
        margin-left: -($screen-xxl-container / 2) - $sticky-box-width - $sticky-box-margin;
    }

    .sticky-box {
        margin-top: 15px;
        height: 200px;
        width: $sticky-box-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

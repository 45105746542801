input.form-control {
    border-width: 2px;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }
}

.input-group-btn {
    button {
        border-width: 2px;
        padding: 3px 10px;
        border-left-width: 0;
        background: $secondary-color;
        color: $color-default-white;

        &:hover, &:active, &:focus {
            background: $secondary-color;
            border-width: 2px;
            border-left-width: 0;
        }
    }
}
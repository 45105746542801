.icon {
    position: relative;
    vertical-align: middle;
    display: inline-block;
}

.dropdown {
    .dropdown-menu {
        a {
            min-height: 32px;
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-md - 1) {
    .container {
        width: 100%;
        max-width: 800px;
    }
}

.responsive-content-wrapper {
    @media (max-width: $screen-md) {
        overflow: hidden;
    }
}

.responsive-menu-wrapper + .responsive-content-wrapper {
    @media (max-width: $screen-md - 1) {
        width: 100%;
    }

    @media (min-width: $screen-md) {
        width: 767px;
        float: right;
    }

    @media (min-width: $screen-lg) {
        width: 975px;
        float: right;
    }

    @media (min-width: $screen-xl-min) {
        width: calc(100% - 179px - 15px);
    }
    
    .extraSpaceFeatureIsOn & {
        @media (min-width: $screen-md) {
            width: calc(100% - 179px - 15px);
        }
    }
}

::selection {
    background: $main-color;
    color: $selection-color;
}

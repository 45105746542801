//Colors
$main-color: #f8b912;
$secondary-color: #1f1949;

// Links
$link-hover-color: #0074bd;
$link-active-color: $link-hover-color;

//Buttons
$button-primary-background-hover: #fac742;
$button-primary-background-active: #ebb000;
$button-primary-border-bottom-color: #d29300;
$button-primary-disabled-background: rgba($main-color, .7);
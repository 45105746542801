@import "_mixins";

button, a {
    &:focus {
        outline: thin dotted;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
    }
}

a {
    @extend %link-default;
}

.link-collection {
    a {
        @extend %link-collection;
    }
}

@at-root {
    [data-touch=true] a {
        &:hover, &:focus {
            text-decoration: none;
        }
    }
}

@import "_variables";

.poster {
    text-align: left;
    display: inline-block;
    box-sizing: content-box;
    overflow: auto;
    background: $poster-background-color;
    padding: $poster-padding;
    box-shadow: $poster-box-shadow;
    border: 1px solid $poster-border-color;
}

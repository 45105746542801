@import "_variables";

%btn-default {
    border-radius: $button-border-radius;
    border: 0;
    border-bottom: $button-border-bottom;
    border-bottom-width: $button-border-bottom-width;
    cursor: pointer;
    white-space: nowrap;
    text-transform: $button-text-transformation;
    font-size: $button-font-size;
    color: $button-color;
    background: $button-background;
    display: inline-block;
    padding: $button-top-bottom-padding $button-left-right-padding;
    line-height: normal;
    user-select: none;
    position: relative;
    vertical-align: bottom;
    overflow: visible;

    .checkmark {
        display: none;
    }

    &:hover, &:focus {
        color: $button-color;
        background: $button-background-hover;
        text-decoration: $button-hover-text-decoration;
    }

    &:active, &:hover:active {
        color: $button-color;
        background: $button-background-active;
        text-decoration: $button-hover-text-decoration;
        border-bottom-width: $button-border-bottom-width-active;
        margin-top: $button-border-bottom-width - $button-border-bottom-width-active;
    }

    &.btn-icon {
        padding-left: 50px;

        &:before {
            content: '';
            position: absolute;
            height: 100%;
            width: 40px;
            top: 0;
            left: 0;
            background: $button-secondary-background;
            border-radius: $button-border-radius 0 0 $button-border-radius;
            box-shadow: $button-icon-shadow;
        }

        &:hover {
            &:before {
                background-color: $button-secondary-background-hover;
            }
        }

        &:active, &:hover:active {
            &:before {
                background-color: $button-secondary-background-active;
                box-shadow: $button-icon-shadow-active;
            }
        }

        .icon {
            position: absolute;
            color: $button-secondary-color;
            top: 9px;
            left: 10px;
            text-align: center;
        }
    }

    &.primary {
        color: $button-primary-color;
        background: $button-primary-background;
        border-bottom-color: $button-primary-border-bottom-color;

        &:hover, &:focus {
            color: $button-primary-color;
            background: $button-primary-background-hover;
        }

        &:active, &:hover:active {
            color: $button-primary-color;
            background: $button-primary-background-active;
        }

        &.disabled {
            color: $button-primary-disabled-color;
            background-color: $button-primary-disabled-background;

            &:active, &:hover:active {
                background-color: $button-primary-disabled-background;
            }
        }
    }

    &.secondary {
        color: $button-secondary-color;
        background: $button-secondary-background;
        border-bottom-color: $button-secondary-border-bottom-color;

        &.btn-icon {
            padding-left: 40px;

            &.btn-large {
                padding-left: 30px;

                .icon {
                    left: 15px;
                }
            }
        }

        &:hover, &:focus {
            color: $button-secondary-hover-focus-color;
            background: $button-secondary-background-hover;
        }

        &:active, &:hover:active {
            color: $button-secondary-hover-focus-color;
            background: $button-secondary-background-active;
        }
    }

    &.flat {
        color: $button-flat-color;
        background: $button-flat-background;
        font-weight: $button-flat-font-weight;
        border-bottom: 0;

        &:hover, &:focus {
            color: $button-flat-color;
            background: $button-flat-background-hover;
        }

        &:active, &:hover:active {
            color: $button-flat-color;
            background: $button-flat-background-active;
            margin-top: 0;
        }

        &.btn-icon {
            &:before {
                box-shadow: none;
            }
        }
    }

    &.disabled {
        color: $button-disabled-color;
        background: $button-disabled-background;
        border-bottom: 0;

        &:active, &:hover:active {
            background: $button-disabled-background;
            color: $button-disabled-color;
            margin-top: 0;
        }

        &.btn-icon {
            &, &:active {
                &:before {
                    box-shadow: none;
                    background: darken($button-disabled-background, 10%);
                }
            }
        }

        &.with-loader {
            &:hover, &:focus, &:active, &:hover:active {
                color: $button-disabled-color;
                background: $button-disabled-background;
                outline: none;
            }
        }
    }

    &.tertiary-white {
        background: $button-tertiary-white-background-color;
        border-top: $button-tertiary-white-border;
        border-right: $button-tertiary-white-border;
        border-left: $button-tertiary-white-border;

        &:hover, &:focus {
            background: $button-tertiary-white-background-hover;
        }

        &:active, &:hover:active {
            background: $button-tertiary-white-background-active;
        }
    }

    &.tertiary-grey {
        background: $button-tertiary-grey-background-color;
        border-top: $button-tertiary-grey-border;
        border-right: $button-tertiary-grey-border;
        border-left: $button-tertiary-grey-border;

        &:hover, &:focus {
            background: $button-tertiary-grey-background-hover;
        }

        &:active, &:hover:active {
            background: $button-tertiary-grey-background-active;
        }
    }

    &.with-loader {
        pointer-events: none;
    }

    &.round-transparent {
        float: none;
        width: $button-round-show-more-size;
        min-width: $button-round-show-more-size;
        height: $button-round-show-more-size;
        border-radius: 50%;
        border: $button-round-show-more-border-size solid $button-round-transparent-color;
        font-size: 16px;
        font-weight: normal;
        color: $button-round-transparent-color;
        text-transform: uppercase;
        background-color: transparent;
        z-index: 0;

        &.loading-more {
            &:hover, &:focus, &:active {
                outline: 0;
            }

            .spin-more-round {
                position: absolute;
                margin: 0 auto;
                width: $button-round-show-more-spinner-size;
                height: $button-round-show-more-spinner-size;
                background-color: $color-default-white;
                -webkit-animation: spin-loading-more 1s linear infinite;
                animation: spin-loading-more 1s linear infinite;
                -webkit-transform-origin: $button-round-show-more-spinner-transform-top $button-round-show-more-spinner-transform-left;
                transform-origin: $button-round-show-more-spinner-transform-top $button-round-show-more-spinner-transform-left;
                border-radius: 50%;
                top: $button-round-show-more-spinner-top;
                left: $button-round-show-more-spinner-left;
            }

            .not-loading-text {
                display: none;
            }

            .loading-text {
                display: inline;
            }
        }

        &:hover, &:focus {
            margin-top: 0;
            background-color: transparent;
            border: $button-round-show-more-border-size solid $button-round-transparent-color-hover;
            color: $button-round-transparent-color-hover;
        }

        &:active {
            margin-top: 0;
            outline: 0;
        }

        .not-loading-text {
            display: inline;
        }

        .loading-text {
            display: none;
        }
    }

    &.added-to-cart, &.added-to-cart:active, &.added-to-cart:hover, &.added-to-cart:focus, &.added-to-cart:focus:active {
        background: $button-add-to-cart-background;
        border-bottom-width: 2px;
        margin-top: 1px;
        border-bottom-color: $button-add-to-cart-border-bottom-color;
        color: $button-add-to-cart-color;
        cursor: default;

        .text {
            margin-left: 35px;
        }

        $color--green: $color-default-green;
        $curve: cubic-bezier(0.650, 0.000, 0.450, 1.000);

        .checkmark__circle {
            stroke-dasharray: 166;
            stroke-dashoffset: 166;
            stroke-width: 2;
            stroke-miterlimit: 10;
            stroke: $color--green;
            fill: none;
            animation: stroke .6s $curve forwards;
        }

        .checkmark {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            display: inline-block;
            stroke-width: 3;
            stroke: #fff;
            stroke-miterlimit: 10;
            box-shadow: inset 0px 0px 0px $color--green;
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
            vertical-align: middle;
            margin-top: $button-add-to-cart-checkmark-margin-lg;
            position: absolute;
        }

        .checkmark__check {
            transform-origin: 50% 50%;
            stroke-dasharray: 48;
            stroke-dashoffset: 48;
            animation: stroke .3s $curve .8s forwards;
        }

        @keyframes stroke {
            100% {
                stroke-dashoffset: 0;
            }
        }

        @keyframes scale {
            0%, 100% {
                transform: none;
            }

            50% {
                transform: scale3d(1.1, 1.1, 1);
            }
        }

        @keyframes fill {
            100% {
                box-shadow: inset 0px 0px 0px 30px $color--green;
            }
        }
    }

    &.background-transition {
        transition: background 0.5s;
    }
}

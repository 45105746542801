﻿/*Global variables for all stores
  These variablels are loaded before the store specific variables
*/

$text-color-default: $color-default-black;
$font-size-default: 13px;
$menu-font-size-default: 12px;

$body-background-color-default: $color-default-light-grey;

$border-color: $color-default-mid-grey;
$border-alt-color: #b6afa9;

$link-color: #0074bd;
$link-collection-color: $color-default-black;

@import "_variables";
@import "_placeholders";

form.responsive-form {
    font-size: $formlabel-font-size;

    label {
        max-width: 100%;
        font-weight: normal;
    }

    .input-set {
        margin-bottom: 10px;

        .validation-message {
            color: $inputfield-invalid-color;
        }

        label {
            font-weight: bold;
            margin-bottom: $inputfield-label-margin-bottom;
            display: block;
        }

        .icon-input-wrapper {
            position: relative;
            display: inline-table;
            border-collapse: separate;
            width: 100%;
            vertical-align: middle;

            .input-icon {
                display: table-cell;
                background-color: $inputfield-icon-background;
                border-radius: $inputfield-border-radius 0 0 $inputfield-border-radius;
                border-right: 0;
                padding: 4px 8px;
                font-weight: normal;
                line-height: 1;
                text-align: center;
                width: $input-icon-width;
                white-space: nowrap;
                vertical-align: middle;
            }

            input {
                #{$input-types} {
                    display: table-cell;
                    float: left;
                    position: relative;
                    vertical-align: middle;
                    border-radius: 0 $inputfield-border-radius $inputfield-border-radius 0;
                    border: 2px solid $inputfield-border-color;
                    border-left: 0;
                    line-height: normal;

                    &:focus {
                        border-left: 0;
                    }
                }

                &[type="date"]{
                    height: $input-icon-total-height;
                }
            }
        }

        input {
            #{$input-types} {
                width: 100%;
                background-color: $inputfield-background;
                border-radius: $inputfield-border-radius;
                border: 2px solid $inputfield-border-color;
                padding: 6px 8px;
                font-size: $inputfield-font-size;
                font-weight: normal;
                -webkit-appearance: none;

                &:focus {
                    outline: 0;
                    background-color: $inputfield-background-focus-color;
                    border: 2px solid $inputfield-focus-color;
                }
            }
        }

        &.invalid {
            .input-icon {
                background-color: $inputfield-invalid-color;
            }


            input {
                #{$input-types} {
                    border: 2px solid $inputfield-invalid-color;
                    padding-right: 25px;
                }
            }

            .icon-input-wrapper {
                &::after {
                    display: block;
                    content: "";
                    @extend #{$icon-input-wrapper} !optional;
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                input {
                    #{$input-types} {
                        border-left: 0;
                    }
                }
            }
        }

        &.white {
            input {
                #{$input-types} {
                    background-color: $color-default-white;
                }
            }
        }
    }

    .textarea-set {
        margin-bottom: 15px;

        label {
            font-weight: bold;
            display: block;
        }

        textarea {
            display: block;
            width: 100%;
            resize: none;
            background-color: $inputfield-background;
            border-radius: $inputfield-border-radius;
            font-size: $inputfield-font-size;
            font-weight: normal;
            border: 2px solid $inputfield-border-color;
            -webkit-appearance: none;
            padding: 7px;

            &:focus {
                outline: 0;
                background-color: $inputfield-background-focus-color;
                border: 2px solid $inputfield-focus-color;
            }
        }

        .validation-message{
            color: $inputfield-invalid-color;
        }

        .mError {
            color: $inputfield-invalid-color;
            text-align: center;
        }

        &.invalid {
            textarea {
                border: 2px solid $inputfield-invalid-color;
            }
        }

        &.white {
            textarea {
                background-color: $color-default-white;
            }
        }
    }

    .radio-set, .checkbox-set {
        .input {
            display: block;
            width: 100%;
        }

        .validation-message {
            color: $inputfield-invalid-color;
        }

        legend {
            font-weight: bold;
            font-size: $formlabel-font-size;
            border: 0;
            margin-bottom: 0;
        }

        label {
            cursor: pointer;
        }
    }

    .radio-set {
        position: relative;

        .input {
            position: relative;
        }

        input[type="radio"] {
            @extend %hidden-radio-checkbox;

            & + label::before {
                visibility: visible;
                content: " ";
                display: inline-block;
                cursor: pointer;
                margin-right: 5px;
                position: relative;
                top: 4px;
                @extend #{$radiobtn-unchecked-icon} !optional;
            }

            &:focus + label::before {
                @extend #{$radiobtn-unchecked-focus-icon} !optional;
            }

            &:checked + label::before {
                @extend #{$radiobtn-checked-icon} !optional;
            }

            &:checked:focus + label::before {
                @extend #{$radiobtn-checked-focus-icon} !optional;
            }
        }

        input[type="radio"]:disabled {

            & + label {
                cursor: not-allowed;
            }

            & + label::before {
                @extend #{$radiobtn-unchecked-disabled-icon} !optional;
                cursor: not-allowed;
            }

            &:checked + label::before {
                @extend #{$radiobtn-checked-disabled-icon} !optional;
            }

            &:checked:focus + label::before {
                @extend #{$radiobtn-checked-focus-icon} !optional;
            }
        }

        label {
            user-select: none;
        }
    }

    .checkbox-set {
        position: relative;

        .input {
            position: relative;
        }

        input[type="checkbox"] {
            @extend %hidden-radio-checkbox;

            & + label::before {
                content: " ";
                display: inline-block;
                cursor: pointer;
                margin-right: 5px;
                position: relative;
                vertical-align: text-bottom;
                @extend #{$checkbox-unchecked-icon} !optional;
            }

            &:focus + label::before {
                @extend #{$checkbox-unchecked-focus-icon} !optional;
            }

            &:checked + label::before {
                @extend #{$checkbox-checked-icon} !optional;
            }

            &:checked:focus + label::before {
                @extend #{$checkbox-checked-focus-icon} !optional;
            }

            &.checkbox-white {
                & + label::before {
                    @extend #{$checkbox-unchecked-icon-white} !optional;
                }

                &:focus + label::before {
                    @extend #{$checkbox-unchecked-focus-icon-white} !optional;
                }

                &:checked + label::before {
                    @extend #{$checkbox-checked-icon-white} !optional;
                }

                &:checked:focus + label::before {
                    @extend #{$checkbox-checked-focus-icon-white} !optional;
                }
            }
        }

        input[type="checkbox"]:disabled {

            & + label {
                cursor: not-allowed;
            }

            & + label::before {
                @extend #{$checkbox-unchecked-disabled-icon} !optional;
                cursor: not-allowed;
            }

            &:checked + label::before {
                @extend #{$checkbox-checked-disabled-icon} !optional;
            }

            &:checked:focus + label::before {
                @extend #{$checkbox-checked-focus-icon} !optional;
            }

            &.checkbox-white {
                & + label::before {
                    @extend #{$checkbox-unchecked-disabled-icon-white} !optional;
                }
            }
        }

        label {
            user-select: none;
        }
    }
}

input {
    #{$input-types} {
        font-size: $inputfield-font-size;
    }
}
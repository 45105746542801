$close-button-clickable-close-area: 40 !default;
$close-button-right: 15px !default;
$close-button-top: 10px !default;
$pre-order-button-background: #0074bd ;
$pre-order-button-background-hover: #0082d4 ;
$pre-order-button-background-active: #006cb0 ;
$pre-order-button-border-bottom-color: #005388 ;
$button-add-to-cart-background: #fefafc !default;
$button-add-to-cart-border-bottom-color: #d0cbce !default;

[class^="btn-"].disabled {
    pointer-events: none;
}

.close-button {
    width: $close-button-clickable-close-area + px;
    height: $close-button-clickable-close-area + px;
    line-height: $close-button-clickable-close-area - 1 + px;
    right: -$close-button-right;
    top: -$close-button-top;
    position: absolute;
    display: inline-block;
    text-align: center;

    .close-icon {
        vertical-align: middle;
        display: inline-block;
        @extend .fa-darkgrey-times;
        @extend .icon-sm;
    }

    &:hover, &:focus {
        .close-icon {
            @extend .fa-lightgrey-times;
            @extend .icon-sm;
        }
    }
}

.secondary.pre-order-button {
    background-color: $pre-order-button-background;
    border-bottom-color: $pre-order-button-border-bottom-color;

    &:hover, &:focus {
        background: $pre-order-button-background-hover;
    }

    &:active {
        background: $pre-order-button-background-active;
    }

    &.added-to-cart, &.added-to-cart:active, &.added-to-cart:hover, &.added-to-cart:focus, &.added-to-cart:focus:active {
        background-color: $button-add-to-cart-background;
        border-bottom-color: $button-add-to-cart-border-bottom-color;
    }
}

.btn-ghost {
    border: 0;
    background-color: transparent;
    padding: 0;

    &:hover {
        text-decoration: underline;
    }
}

$color-default-black: #222;
$color-default-grey: #ccc;
$color-default-mid-grey: #e6e6e6;
$color-default-light-grey: #f5f5f5;
$color-default-extra-light-grey: #fafafa;

// Used at least with the icons
$color-default-dark-blue: #2b3841;
$color-default-dark-grey: #383838;
$color-default-green: #308355;
$color-default-light-grey-icons: #cdcac5;
$color-default-maroon: #bb0000;
$color-default-orange: #ffba00;
$color-default-red: #fe0000;
$color-default-white: #fff;
@import "_variables";

.button-list-item {
    @extend %btn-default;
    @extend .card;
    display: block;
    padding: 10px;

    text-transform: none;
    text-align: left;
    width: 100%;
    margin-top: $button-list-margin;
    margin-bottom: $button-list-margin;
    border-bottom: 0;

    white-space: normal;

    &:active,
    &:hover:active {
        margin-top: $button-list-margin;
    }

    em {
        display: flex;
        font-style: normal;
        font-weight: bold;

        .button-list-item-name {
            width: 100%;
            @extend %fix-browser-ie-flexbox-wrap-text !optional;
        }
    }

    hr {
        margin: 5px 0;
    }
    
    .button-loader {
        @extend %button-loader;
        background-color: transparent;
    }
    
    &:disabled {
        opacity: 0.7; 
        box-shadow: none;
        color: inherit;
        pointer-events: none;
    }
}
.page-linear-spinner-overlay {
    background: rgba($overlay-color, 0.3);
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000; //overwritable with knockout binding
    .page-linear-spinner-centered {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.linear-spinner {
    width: 70px;
    text-align: center;
    display: inline-block;

    > div {
        width: 18px;
        height: 18px;
        background-color: $color-default-white;
        box-shadow: 0 0 16px 0 $color-default-white;
        border-radius: 100%;
        display: inline-block;
        animation: bouncedelay 1.4s infinite ease-in-out;
        /* Prevent first frame from flickering when animation starts */
        animation-fill-mode: both;
    }

    .bounce1 {
        animation-delay: -0.32s;
    }

    .bounce2 {
        animation-delay: -0.16s;
    }
}

.linear-spinner-gray {
    @extend .linear-spinner;

    > div {
        background-color: #777;
    }
}

@keyframes bouncedelay {
    0%, 80%, 100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

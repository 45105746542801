//Inputfield
$inputfield-font-size: 16px !default;
$formlabel-font-size: 16px !default;
$inputfield-border-radius: 0 !default;
$inputfield-icon-background: #476471 !default;
$inputfield-background: $color-default-light-grey !default;
$inputfield-background-focus-color: #fcfcfc !default;
$inputfield-border-color: #e6e6e6 !default;
$inputfield-focus-color: #476471 !default;
$inputfield-invalid-color: $color-default-maroon !default;
$inputfield-color: $color-default-light-grey !default;
$inputfield-label-margin-bottom: 3px !default;
$icon-input-wrapper: '.fa-colored-exclamation-circle-maroon' !default;
$checkbox-checked-icon: '.pageElements-checkbox-checked' !default;
$checkbox-checked-disabled-icon: '.pageElements-checkbox-checked-disabled' !default;
$checkbox-checked-focus-icon: '.pageElements-checkbox-checked-focus' !default;
$checkbox-unchecked-icon: '.pageElements-checkbox-unchecked' !default;
$checkbox-unchecked-disabled-icon: '.pageElements-checkbox-unchecked-disabled' !default;
$checkbox-unchecked-focus-icon: '.pageElements-checkbox-unchecked-focus' !default;

$checkbox-unchecked-icon-white: '.pageElements-checkbox-unchecked_white' !default;
$checkbox-unchecked-focus-icon-white: '.pageElements-checkbox-unchecked-focus_white' !default;
$checkbox-checked-icon-white: '.pageElements-checkbox-checked_white' !default;
$checkbox-checked-focus-icon-white: '.pageElements-checkbox-checked-focus_white' !default;
$checkbox-unchecked-disabled-icon-white: '.pageElements-checkbox-unchecked-disabled_white' !default;
$checkbox-unchecked-focus-icon-white: '.pageElements-checkbox-unchecked-focus_white' !default;

$radiobtn-checked-icon: '.pageElements-radiobtn-checked' !default;
$radiobtn-checked-disabled-icon: '.pageElements-radiobtn-checked-disabled' !default;
$radiobtn-checked-focus-icon: '.pageElements-radiobtn-checked-focus' !default;
$radiobtn-unchecked-icon: '.pageElements-radiobtn-unchecked' !default;
$radiobtn-unchecked-disabled-icon: '.pageElements-radiobtn-unchecked-disabled' !default;
$radiobtn-unchecked-focus-icon: '.pageElements-radiobtn-unchecked-focus' !default;
$input-icon-width: 36px !default;
$input-icon-height-padding: 4px !default;
$input-icon-total-height: $input-icon-width + $input-icon-height-padding !default;

$input-types: '&[type="text"], &[type="email"], &[type="password"], &[type="number"], &[type="tel"], &[type="url"], &[type="date"]' !default;
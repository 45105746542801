*,
:after,
:before {
    box-sizing: border-box;
}

#container,
.main-body {
    padding-top: $grid-space;
    padding-bottom: $body-padding-bottom;
}

.main-body {
    background: $container-background;

    @media (max-width: $screen-xs-max) {
        &.ignore-gutter-xs {
            padding-left: 0;
            padding-right: 0;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.maincontent-container {
    padding-left: 15px;
    padding-right: 15px;
}

button {
    font-size: inherit;
}

.visible-xs,
.visible-sm {
    display: none !important;
}

@media (max-width: $screen-xs-max) {
    .hidden-xs {
        display: none !important;
    }

    .visible-xs {
        display: block !important;
    }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    .hidden-sm {
        display: none !important;
    }

    .visible-sm {
        display: block !important;
    }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: $screen-lg-min) {
    .hidden-lg {
        display: none !important;
    }
}

.hidden-visible-SR {
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    color: transparent;
    background: transparent;
    border: 0;
}

// A replacement for a centered grid column
.no-bs-center {
    margin: 0 auto;

    @media (max-width: $screen-xs-max) {
        margin-left: 10px;
        margin-right: 10px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        max-width: 800px;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: $screen-md-min) {
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        max-width: $container-md;
    }

    @media (min-width: $screen-lg-container) {
        width: $screen-lg-container;
    }

    @media(min-width: $screen-xl-min) {
        width: $screen-xl-container;
    }

    @media(min-width: $screen-xxl-min) {
        width: $screen-xxl-container;
    }

    .extraSpaceFeatureIsOn & {
        @media(min-width: $screen-md-min) {
            width: calc(100vw - 48px);
        }

        @media(min-width: $screen-lg-min) {
            width: calc(100vw - 64px);
        }

        @media (min-width: $screen-xxl-min) {
            width: $screen-xxl-container;
        }
    }
    
    &--no-padding {
        padding: 0;
    }
}

// Include the bare minimum stuff from bootstrap that we are still relying on in our code
.form-control {
    height: 34px;
    padding: 6px 12px;
}

// Main search
.navigation-header .search .input-group {
    .search-icon {
        font-size: 20px;
        width: 1em;
        height: 1em;
    }
}

// Hide certain not important elements
.sticky-box-wrapper {
    display: none;
}

// The minimal stuff needed for displaying campaign pages

.campaign-content {
    img {
        max-width: 100%;
    }
}

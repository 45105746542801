$medium-ubo-width: 350px !default;
$large-sm-ubo-width: 480px !default;
$large-md-ubo-width: 550px !default;

.medium-ubo-width {
    @media (min-width: $screen-sm-min) {
        width: $medium-ubo-width;
    }
}

.large-ubo-width {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        width: $large-sm-ubo-width;
    }

    @media (min-width: $screen-md-min) {
        width: $large-md-ubo-width;
    }
}

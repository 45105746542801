$swiper-pagination-bullet-size: 20px !default;
$swiper-pagination-bullet-border-size: 2px !default;

$pagination-bullet-background: rgba(0, 0, 0, 0.8);
$pagination-bullet-background-active: rgba(115, 115, 115, 0.5);
$pagination-bullet-border: 1px solid rgba(255, 255, 255, 0.8);

$swiper-pagination-color-grey: #545454 !default;
$swiper-pagination-color-white: $color-default-white !default;
$swiper-pagination-next-button-background-color: #545454;
$swiper-pagination-next-button-border-color: $color-default-white;

.swiper-container {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    z-index: 1;

    @media (max-width: $screen-md - 1) {
        overflow: visible;
    }

    &-vertical > .swiper-wrapper {
        flex-direction: column;
    }
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    transform-style: preserve-3d;
    transition-property: transform;
    z-index: 1;

    &,
    .swiper-container-android .swiper-slide {
        transform: translate3d(0, 0, 0);
    }
}

.swiper-slide {
    transform-style: preserve-3d;
    text-align: center;
    flex-shrink: 0;
    width: 100%;
    position: relative;
    font-size: 18px;
    cursor: pointer;

    @media (max-width: $screen-md - 1) {
        width: 80%;
    }

    &-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: $color-default-white;
        border-radius: 16px;
        vertical-align: center;
        cursor: pointer;
        padding: 6px;
        z-index: 2;
    }

    &-left-button {
        left: -1px;
    }

    &-right-button {
        right: 0;
    }

    &-right-arrow {
        position: relative;
        left: 2px;
    }

    &-left-arrow {
        position: relative;
        right: 2px;
    }
}

.swiper-pagination-wrapper {
    height: 100%;
    position: relative;
    z-index: 3;

    .swiper-pagination {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        position: relative;
        top: calc(100% - 40px);
        vertical-align: top;
        pointer-events: all;

        @media (max-width: $screen-md - 1) {
            top: calc(100% + 10px);
        }

        &-bullet {
            display: inline-block;
            border-radius: 100%;
            width: $swiper-pagination-bullet-size;
            height: $swiper-pagination-bullet-size;
            background: $pagination-bullet-background;
            border: $pagination-bullet-border;
            margin-left: 8px;

            @media (max-width: $screen-md - 1) {
                width: 10px;
                height: 10px;
                background: #c4c4c4;
            }
        }

        &-bullet-active {
            background: $pagination-bullet-background-active;
            border: $pagination-bullet-border;

            @media (max-width: $screen-md - 1) {
                background: #e1a83d;
            }
        }
    }

    .next-button,
    .previous-button {
        transition: opacity 0.2s ease-in-out;
        opacity: 0;
        min-width: 50px;
        height: 100% !important;
        pointer-events: all;

        &::after {
            background: rgba(0, 0, 0, 0.4);
            padding: 40px 15px;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            opacity: 1;
        }

        @media (max-width: $screen-md - 1) {
            display: none;
        }
    }

    .next-button {
        position: absolute;
        top: 0;
        right: -6px;
        &::after {
            content: "";
            background-image: url("/caas/resources/slide-arrow-next.svg");
            background-repeat: no-repeat;
            background-position: center;
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
        }
    }

    .previous-button {
        position: absolute;
        top: 0;
        left: -6px;
        &::after {
            content: "";
            background-image: url("/caas/resources/slide-arrow-prev.svg");
            background-repeat: no-repeat;
            background-position: center;
            flex: 1 1 auto;
            display: flex;
            justify-content: center;
        }
    }
}

.swiper-previous-body-next-container {
    /* stylelint-disable declaration-block-no-duplicate-properties */
    display: inline-block;
    display: flex;
    /* stylelint-enable */
    align-items: center;

    .previous,
    .next {
        flex: 0 0 32px;
        height: 40px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .body {
        flex: 1 1 auto;
    }
}

.swiper-fraction {
    height: 20px;
}

.swiper-pagination {
    position: absolute;
    text-align: center;
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 10;
    &.swiper-pagination-hidden {
        opacity: 0;
    }
}

/* Bullets */
.swiper-pagination-bullet {
    width: 11px;
    height: 11px;
    display: inline-block;
    border-radius: 100%;
    background: #c4c4c4;

    .swiper-pagination-clickable & {
        cursor: pointer;
    }
}

.swiper-pagination-bullet-active {
    opacity: 1;
    background: #444;
}

.swiper-pagination-bullets {
    .swiper-pagination-bullet {
        margin: 0 10px;
    }
}

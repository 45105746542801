@import "_variables";

%button-loader {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.7);
  top: 0;
  left: 0;
  bottom: 0;
  cursor: not-allowed;

  .circular {
    animation: rotate 1s linear infinite;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: $button-loader-width ;
    width: $button-loader-width;
    fill: none; 
    stroke-width: 2;

    .path {
      transform-origin: $button-loader-width/2 $button-loader-width/2;
      animation: dash 1.5s ease-in-out infinite;
      stroke: $button-loader-color;
      stroke-dasharray: 60, 100;
      stroke-linecap: round;
    }
  }
}


@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: $button-loader-offset + 10;
    stroke-dashoffset: $button-loader-offset + 10; }
  50% {
    stroke-dasharray: $button-loader-offset + 10;
    stroke-dashoffset: $button-loader-offset/4;
    transform:rotate(135deg);
  }
  100% {
    stroke-dasharray: $button-loader-offset + 10;
    stroke-dashoffset: $button-loader-offset + 10;
    transform:rotate(360deg);
  }
}

@keyframes dash-medium {
  0% {
    stroke-dasharray: $button-loader-offset;
    stroke-dashoffset: $button-loader-offset; }
  50% {
    stroke-dasharray: $button-loader-offset;
    stroke-dashoffset: $button-loader-offset/4;
    transform:rotate(135deg);
  }
  100% {
    stroke-dasharray: $button-loader-offset;
    stroke-dashoffset: $button-loader-offset;
    transform:rotate(360deg);
  }
}

@keyframes dash-small {
  0% {
    stroke-dasharray: $button-loader-offset - 15;
    stroke-dashoffset: $button-loader-offset - 15; }
  50% {
    stroke-dasharray: $button-loader-offset - 15;
    stroke-dashoffset: $button-loader-offset/4;
    transform:rotate(135deg);
  }
  100% {
    stroke-dasharray: $button-loader-offset - 15;
    stroke-dashoffset: $button-loader-offset - 15;
    transform:rotate(360deg);
  }
}

@keyframes spin-loading-more {
    from {
        transform: rotate(0deg); 
    }

    to {
        transform: rotate(360deg); 
    } 
}
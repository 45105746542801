﻿@import "_mixins";

.btn-large .button-loader {
  @extend %button-loader;
}

.btn-medium .button-loader {
  @extend %button-loader;
  
  .circular .path {
    stroke-dasharray: 50, 100;
    animation: dash-medium 1.5s ease-in-out infinite;
  }
}

.btn-small .button-loader {
  @extend %button-loader;

  .circular .path {
    stroke-dasharray: 35, 100;
    animation: dash-small 1.5s ease-in-out infinite;
  }
}

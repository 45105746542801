//Normalize
/* stylelint-disable */
@import "../../../bower_components/normalize.css/normalize";
@import "../bootstrap-sass-imported/scaffolding";
//mixins
@import "_mixins";
//placeholders
@import "_placeholders";

html {
    @media (max-width: $screen-sm-max) {
        &.scroll-lock-sm {
            &,
            body {
                @include scroll-lock();
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        &.scroll-lock-xs {
            &,
            body {
                @include scroll-lock();
            }
        }
    }

    &.scroll-lock {
        &,
        body {
            @include scroll-lock();
        }
    }

    body {
        font-family: $font-family-base;
        font-size: $font-size-default;
        color: $text-color-default;

        @media (max-width: $screen-sm-max) {
            background: $body-background-color-default;

            &.i-am-fixed-on-small-screens {
                position: fixed;
                width: 100vw;
            }

            &.fix-scrolling {
                position: fixed;
            }
        }
    }
}

html,
body {
    display: flex;
    flex-direction: column;
    position: relative;
    scroll-behavior: smooth;
}

.caas {
    flex: 0 0 auto;
}

@media (min-width: $screen-md) {
    #MainContent {
        flex: 1 0 auto;
    }
}

.megaMobileNavOpen {
    @media (max-width: $screen-sm-max) {
        #MainContent,
        .footer,
        .environment-info,
        .main-header,
        .search-button,
        .cart-button {
            display: none;
        }
    }
}

#MainContent {
    .main-body {
        width: 100%;

        @media (min-width: $screen-md-min) {
            flex: 0 0 $screen-md;
            max-width: $screen-md; /* IE11, do not grow bigger than... */
        }

        @media (min-width: $screen-lg-min) {
            flex: 0 0 1200px;
            max-width: 1200px; /* IE11, do not grow bigger than... */
        }

        @media (min-width: $screen-xl-min) {
            flex: 0 0 $screen-xl-container;
            max-width: $screen-xl-container;
        }

        @media (min-width: $screen-xxl-min) {
            flex: 0 0 $screen-xxl-container;
            max-width: $screen-xxl-container;
        }

        .extraSpaceFeatureIsOn & {
            @media (min-width: $screen-md-min) {
                flex: 0 0 calc(100vw - 48px);
                max-width: calc(100vw - 48px);
            }

            @media (min-width: $screen-lg-min) {
                flex: 0 0 calc(100vw - 64px);
                width: calc(100vw - 64px);
                max-width: $screen-xxl-container;
            }

            @media (min-width: $screen-xxl-min) {
                flex: 0 0 $screen-xxl-container;
                max-width: $screen-xxl-container;
            }
        }
    }
}

//Button List
$button-list-margin: 8px !default;

//Button Loader
$button-loader-color: $color-default-dark-blue !default;
$button-loader-width: 30px !default;
$button-loader-offset: 67 !default;

//Buttons
$button-border-bottom: solid $color-default-grey !default;
$button-font-size: 12px !default;
$button-color: $color-default-black !default;

$button-border-bottom-width: 3px !default;
$button-border-bottom-width-active: 2px !default;

$button-background: $color-default-light-grey !default;
$button-background-hover: $color-default-white !default;
$button-background-active: $color-default-mid-grey !default;
$button-left-right-padding: 12px !default;
$button-top-bottom-padding: 10px !default;
$button-small-top-bottom-padding: 5px !default;
$button-small-left-right-padding: 12px !default;
$button-large-top-bottom-padding: 13px !default;
$button-border-radius: 4px !default;
$button-hover-text-decoration: none !default;
$button-text-transformation: uppercase !default;

$button-primary-color: $button-color !default;
$button-primary-background: $main-color !default;
$button-primary-background-hover: #fac742 !default;
$button-primary-background-active: #ebb000 !default;
$button-primary-border-bottom-color: #d29300 !default;

$button-secondary-color: $color-default-white !default;
$button-secondary-hover-focus-color: $color-default-white !default;
$button-secondary-background: #476471 !default;
$button-secondary-background-hover: #374A53 !default;
$button-secondary-background-active: #435f6b !default;
$button-secondary-color: $color-default-white !default;
$button-secondary-hover-focus-color: $button-secondary-color !default;
$button-secondary-border-bottom-color: #354a54 !default;

$button-flat-background: $color-default-white !default;
$button-flat-background-hover: $color-default-light-grey !default;
$button-flat-background-active: $color-default-mid-grey !default;
$button-flat-font-weight: bold !default;
$button-flat-color: #476471 !default;
$button-flat-border-bottom-color: #354a54 !default;

$button-disabled-color: #6c6c6c !default;
$button-disabled-background: $color-default-light-grey !default;

$button-primary-disabled-background: $button-disabled-background !default;
$button-primary-disabled-color: $button-disabled-color !default;

$button-tertiary-white-background-color: $color-default-white !default;
$button-tertiary-white-background-hover: #f9f9f9 !default;
$button-tertiary-white-background-active: #e5e5e5 !default;
$button-tertiary-white-border-bottom-color: $color-default-grey !default;
$button-tertiary-white-border:1px solid $color-default-mid-grey !default;

$button-tertiary-grey-background-color: $color-default-light-grey !default;
$button-tertiary-grey-background-hover: #f9f9f9 !default;
$button-tertiary-grey-background-active: #e5e5e5 !default;
$button-tertiary-grey-border-bottom-color: $color-default-grey !default;
$button-tertiary-grey-border: 1px solid $color-default-grey !default;

$button-icon-shadow: 0 $button-border-bottom-width 0 $button-secondary-border-bottom-color !default;
$button-icon-shadow-active: 0 $button-border-bottom-width-active 0 $button-secondary-border-bottom-color !default;

$button-round-transparent-color: #476471 !default;
$button-round-transparent-color-hover: #354a54 !default;
$button-round-transparent-border: none !default;
$button-round-transparent-border-hover: none !default;

$button-round-show-more-size: 105px !default;
$button-round-show-more-border-size: 2px !default;
$button-round-show-more-spinner-size: 15px !default;
$button-round-show-more-spinner-top: $button-round-show-more-size/2 - $button-round-show-more-spinner-size/2 - $button-round-show-more-border-size !default;
$button-round-show-more-spinner-left: $button-round-show-more-size + $button-round-show-more-border-size*2 - $button-round-show-more-spinner-size !default;
$button-round-show-more-spinner-transform-top: -$button-round-show-more-spinner-top !default;
$button-round-show-more-spinner-transform-left: $button-round-show-more-spinner-size/2 !default;

$button-large-min-width: 250px !default;

$dropdown-btn-padding: 20px !default;
$dropdown-icon-right-indent: 10px !default;

$button-add-to-cart-background: #fefafc;
$button-add-to-cart-border-bottom-color: #d0cbce;
$button-add-to-cart-color: $color-default-black !default;
$button-add-to-cart-checkmark-margin-lg: -6px !default;
$button-add-to-cart-checkmark-margin-md: -4px !default;
$button-add-to-cart-checkmark-margin-sm: -1px !default;
.swipe-container {
    position: relative;
    width: 100%;
    overflow: hidden;

    #swipe-nav-container {
        position: absolute;
        z-index: 10;
        background-color: green;
        height: 0;
        width: 0;

        .swipe-nav {
            .swipe-button {
                display: inline-block;
                cursor: pointer;
                position: absolute;
                opacity: 0.3;

                &:hover, &:focus {
                    opacity: 0.6;
                }

                &.swipe-prev {
                    height: 32px;
                    width: 32px;
                    left: 0;
                    background-color: gray;
                }

                &.swipe-next {
                    height: 32px;
                    width: 32px;
                    right: 0;
                    background-color: gray;
                }

                &.swipe-play, &.swipe-stop {
                    opacity: 0.8;
                    background: none repeat scroll 0 0 #ddd;
                    display: inline-block;
                    font-weight: bold;
                    text-align: center;
                    text-decoration: none;
                    border-radius: 3px;
                    padding: 5px;
                    width: 30px;
                    line-height: 17px;
                    color: #1f1949;

                    &:hover, &:focus {
                        text-decoration: none;
                        opacity: 0.5;
                    }
                }
            }

            .swipe-pagination {
                list-style-type: circle;
                padding: 0;
                margin: 0;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: -30px;
                position: relative;
                width: 50px;

                .swipe-position {
                    display: inline;
                    float: left;

                    .swipe-position-button {
                        cursor: pointer;
                        color: gray;
                        font-size: 26px;
                        text-decoration: none;

                        &.current {
                            color: $color-default-white;
                        }

                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
    }
}

.swipe {
    overflow: hidden;
    position: relative;
    width: auto;
    margin: 0 auto;
    visibility: visible;

    .swipe-wrap {
        overflow: hidden;
        position: relative;
    }

    .swipe-wrap > div {
        float: left;
        width: 100%;
        position: relative;
    }
}

#swipe-fullscreen.swipe {
    width: auto;
    visibility: hidden;

    .image-fullscreen {
        text-align: center;

        img {
            width: 100%;
        }
    }
}



@import "_variables";

%throbber {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: rgba(255,255,255,0.7);
  display: inline-block;

  .circular {
    animation: throbber-rotate 1s linear infinite;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: $throbber-width ;
    width: $throbber-width;
    fill: none; 
    stroke-width: 2;

    .path {
      transform-origin: $throbber-width/2 $throbber-width/2;
      animation: throbber-dash 1.5s ease-in-out infinite;
      stroke: $throbber-color;
      stroke-dasharray: 60, 100;
      stroke-linecap: round;
    }
  }
}

@keyframes throbber-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes throbber-dash {
  0% {
    stroke-dasharray: $throbber-offset + 10;
    stroke-dashoffset: $throbber-offset + 10; }
  50% {
    stroke-dasharray: $throbber-offset + 10;
    stroke-dashoffset: $throbber-offset/4;
    transform:rotate(135deg);
  }
  100% {
    stroke-dasharray: $throbber-offset + 10;
    stroke-dashoffset: $throbber-offset + 10;
    transform:rotate(360deg);
  }
}
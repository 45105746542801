﻿@import "_variables";

.responsive-table {
    width: 100%;
    background-color: $color-default-white;
    margin-bottom: 20px;
    border: 1px solid $border-color;
    border-top: none;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;

    @media screen and (min-width: $screen-sm) {
        word-wrap: normal;
        table-layout: auto;
        
        &.fixed-layout {
            table-layout: fixed;
        }
    }

    &.even-colors {
        tr:nth-child(even) {
            background: $responsive-table-even-color;
            border-color: $responsive-table-even-border-color;
        }
    }

    &.clickable {
        tbody {
            tr:hover {
                outline: 3px solid $responsive-table-even-border-color;
                cursor: pointer;
            }
        }
    }
    
    caption {
        background-color: $responsive-table-details-bgcolor;
        padding: $responsive-table-details-cell-padding;
        text-align: left;
        font-weight: normal;
        color: $color-default-white;
        font-size: 16px;
        margin: 0;
        line-height: 1.2;
        width: 100%;
        border: none;
    }

    thead {
        background-color: $responsive-table-details-bgcolor;

        th {
            padding: $responsive-table-details-cell-padding;
            text-align: left;
            font-weight: bold;
            color: $color-default-white;
        }
    }

    tbody {
        td, th {
            border: 1px solid $border-color;
            padding: $responsive-table-details-cell-padding;
            text-align: left;
            font-size: 14px;
            font-weight: normal;
        }
    }
}
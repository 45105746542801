@import "overlay";
@import "helpers";
/*#region Variables */

$ubo-background-color: $color-default-white !default;
$ubo-border-color: #999 !default;
$ubo-header-color: $color-default-black !default;
$ubo-header-font-size: 17px !default;
$ubo-default-box-shadow: 5px 6px 9px -4px #000 !default;
$ubo-close-button-clickable-close-area: 45 !default;
$ubo-close-button-right-top: 25px !default;

/*#endregion */

.ubo {
    text-align: center;
    position: absolute;

    &.wide-content {
        .ubo-content {
            h1 {
                font-size: 22px;
            }

            @media (min-width: $screen-sm-min) {
                width: 500px;
            }
        }
    }

    &.centerUbo {
        @media (min-width: $screen-sm-min) {
            .ubo-center-position-wrapper {
                position: relative;
            }

            .ubo-box {
                position: relative;
            }
        }
    }

    .ubo-position-wrapper {
        position: relative;
        width: 1px;
        height: 1px;

        @media (max-width: $screen-sm - 1) {
            position: fixed;
            top: 20px !important;
            left: 0 !important;
            width: 100%;
            text-align: center;
            z-index: 27640;
        }
    }

    .ubo-box {
        position: absolute;
        text-align: left;
        display: inline-block;
        box-sizing: content-box;
        overflow: auto;
		max-height: 700px;
        background: $ubo-background-color;
        padding: 25px;
        box-shadow: $ubo-default-box-shadow;
        border: 1px solid $ubo-border-color;
        -webkit-overflow-scrolling: auto;

        @media (max-width: $screen-sm - 1) {
            position: relative;
            box-sizing: border-box;
            max-width: 92%;
        }

        @media (min-width: $screen-sm) {
            min-width: 200px;
        }

        .ubo-header {
            position: relative;

            .ubo-header-text {
                margin: 0;
                padding: 0;
                color: $ubo-header-color;
                font-size: $ubo-header-font-size;
                padding-right: 20px;
                line-height: 1.2;
                font-weight: 500;
            }
        }

        .ubo-close-button {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: $ubo-close-button-clickable-close-area + px;
            height: $ubo-close-button-clickable-close-area + px;
            top: -$ubo-close-button-right-top;
            right: -$ubo-close-button-right-top;
            z-index: 1;
        }

        .ubo-content {
            margin-top: 15px;
            display: inline-block;

            .ksc-alert {
                margin-bottom: 10px;
            }

            li {
                a {
                    display: block;
                    padding: 5px 0;
                }
            }

            img {
                max-width: 100%;
            }

            .throbber {
                width: 200px; // same as .ubo-box min-width
            }
        }

        .ubo-modal-footer {
            padding-top: 20px;
            height: 40px;
            min-width: 160px;

            > a {
                text-decoration: none;
            }
        }
    }
}
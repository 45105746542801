$navigation-header-toggle-font-size: 18px !default;

@mixin center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@mixin scroll-lock {
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    position: relative;
}

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin navbar-toggle-button {
    z-index: 5;
    border-radius: 0;
    margin: 0;
    font-size: $navigation-header-toggle-font-size;
    border: 0;
    padding: 9px 4px;
}

@mixin for-ie-10-and-11 {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content;
    }
}
